const apiEndpoint = "https://devmercuryrisk.azurewebsites.net";

export const environment = {
	production: true,
	apiEndpoint: apiEndpoint,
	serviceProvider: "Azure",
	msalConfig: {
		auth: {
			clientId: "948ff2a6-6a2a-47cf-a4b2-f3899553d151",
			authority: "https://login.microsoftonline.com/412259ed-cfdd-49be-8186-814dfe58dc54/",
		},
	},
	apiConfig: {
		uri: apiEndpoint,
		scopes: ["api://1f301d77-6d29-4227-b844-8b1caa731273/access_as_user"],
	},
	graphAPI: {
		uri: "https://graph.microsoft.com/",
		scopes: ["user.read", "profile"],
	},
};
